$formGrey: #757575;

// licensing base page
.content-licensing {
    .main-content {
        max-width: 1100px;
        margin: 50px auto 100px;
        @media (max-width: 1100px) {
            width: auto;
        }
        h1 {
            margin: 0 0 30px;
            font-size: 57px;
            font-weight: normal;
            font-family: $minion-condensed;
        }
        h2 {
            margin: 0 0 20px;
            font-size: 2.3rem;
            font-weight: normal;
        }
        h3 {
            margin: 0 0 15px;
            font-size: 1.7rem;
            font-weight: normal;
        }
        p {
            margin: 0 0 48px;
            font-family: $minion;
            font-size: 22px;
            line-height: 165%;
            a {
                text-decoration: underline;
            }
        }
        ul,
        ol {
            font-family: $proxima;
            font-size: 1.1rem;
            line-height: 1.85;
        }
        .two-column-grid {
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 48px;

            @media (max-width: 1000px) {
                grid-template-columns: 1fr;
                gap: 20px;
            }
        }
        .three-column-grid {
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 50px;

            @media (max-width: 1000px) {
                gap: 20px;
            }

            @media (max-width: 800px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .three-column-grid-item {
                img {
                    width: 100%;
                }

                .title {
                    font-size: 1.5rem;
                    font-weight: 700;
                }
            }
        }
        .links-box {
            padding: 30px 40px;
            border: 3px solid #000;
            font-family: $proxima;
            font-size: 1.1rem;
            word-break: break-word;

            @media (max-width: 400px) {
                padding: 15px 20px;
            }
        }
        .black-box {
            height: 60px;
            padding: 20px 50px;
            background: #000;
            color: #fff;
            font-family: $proxima;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .sidebar-page {
        margin: 60px auto;
        display: grid;
        grid-template-columns: 7fr 3fr;
        gap: 48px;

        @media (max-width: 1000px) {
            gap: 20px;
        }

        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }

        .sidebar {
            a {
                margin: 0 0 8px;
                font-family: $proxima;
                font-size: 1.1rem;
                display: block;
            }
        }
    }
    form {
        width: 530px;
        max-width: 100%;
        font-family: $proxima;

        @media (max-width: 600px) {
            width: auto;
        }

        label {
            margin-bottom: 8px;
            font-size: 0.8rem;
            display: block;

            .required {
                color: red;
            }
        }
        fieldset {
            margin: 0 0 20px;
            padding: 0;
            border: none;
            outline: none;
            position: relative;

            input,
            select,
            textarea {
                width: 100%;
                padding: 8px 12px;
                font-size: 0.9rem;
                box-sizing: border-box;
                display: block;
            }
            input[type='checkbox'] {
                width: auto;
                display: inline-block;
            }
            select {
                width: 100%;
                max-width: 100%;
                color: #9e9e9e;
                option:not(:first-of-type) {
                    color: #000;
                }
            }
            textarea {
                height: 180px;
            }
        }
        button,
        .button {
            width: 130px;
            margin: 40px 0;
            padding: 8px 20px;
            background-color: #000;
            color: #fff;
            font-size: 0.9rem;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1px;
            display: block;
            cursor: pointer;
        }
    }
    .grid-element {
        .grid-box {
            background: $button;
            color: $black;
            h3 {
                font-size: 1.2rem;
                font-weight: 600;
                margin: 0;
                padding: 0;
                line-height: 1.4rem;
                span {
                    font-weight: 400;
                }
            }
            a {
                color: $black;
                min-height: 50px;
                padding: 20px 30px;
                font-family: $proxima;
                font-size: 1.2rem;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        p {
            font-size: 18px;
            line-height: 140%;
            padding: 10px 0px;
            margin-bottom: 0;
            font-family: $proxima;
            &.start {
                a {
                    font-weight: 700;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .sidebar {
        h3 {
            background-color: $black;
            color: $white;
            padding: 12px 20px;
            font-family: $proxima;
            font-size: 28px;
            font-weight: bold;
        }
        a {
            font-size: 18px;
            font-weight: bold;
            padding: 0px 20px;
            font-family: $proxima;
        }
        .sidebar-sections {
            padding-bottom: 56px;
        }
    }
}

// licensing forms
body.licensing {
    font-family: $proxima;
    form {
        a {
            font-weight: bold;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .required,
    .errorlist {
        color: $red;
    }
    #missingFieldsMessage {
        max-width: 90%;
        padding: 10px 15px;
        background-color: #ffe5e4;
        color: #d55050;
        font-weight: bold;
        font-style: italic;
        display: none;
        i.fa-circle-exclamation {
            margin-right: 10px;
            color: $red;
        }
    }
    .main-content {
        width: 90%;
        max-width: 1040px;
        // width: 1040px;
        @media (max-width: 600px) {
            width: 100%;
            padding: 0;
        }
        .licensing-main-content {
            @media (max-width: 600px) {
                padding: 0 20px;
            }
        }
        input[type='text'],
        input[type='password'],
        select {
            border: 1px solid $formGrey;
            font-family: $proxima;
            border-radius: 3px;
            padding: 20px 15px 7px 9px;
            margin-bottom: 7px;
            font-size: 15px;
            width: 100%;
            &::placeholder {
                color: $formGrey;
                font-family: $proxima;
                font-size: 0.8rem;
            }
        }
        input[type='text'] {
            width: 100%;
            border: 1px solid $formGrey;
            border-radius: 3px;
            box-sizing: border-box;
            &::placeholder {
                opacity: 0;
                color: $formGrey;
                font-family: $proxima;
                font-size: 15px;
                transition: all 0.1s;
            }
            &:focus::placeholder {
                color: $formGrey !important;
                font-size: 10px;
                position: absolute;
                top: 5px;
                left: 5px;
                display: block !important;
            }
            &.clicked::placeholder {
                display: block !important;
            }
        }
        p.red-border {
            &::after {
                content: '!';
                width: 20px;
                height: 20px;
                padding: 0 1px 3px 2px;
                background: #c00;
                border-radius: 100%;
                color: white;
                text-align: center;
                font-weight: bold;
                position: absolute;
                top: 13px;
                right: 10px;
                display: block;
            }
            label {
                color: $red;
            }
            input {
                border: 1px solid $red;
            }
        }
        input[type='text']::after {
            content: '❗';
            color: red;
            margin-left: 5px;
        }
        select {
            width: 100%;
            padding: 18px 12px 8px 8px;
            border: 1px solid $formGrey;
            border-radius: 3px;
            color: $formGrey;
        }
        .rights-introduction {
            margin: 60px 0 80px 0;
            a {
                font-weight: bold;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .licensee-information {
            width: 100%;
            max-width: 750px;
            // width: 750px;
            @media (max-width: 600px) {
                width: 100%;
            }
            .form-field {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                @media (max-width: 600px) {
                    display: block;
                }
            }
            legend {
                padding-bottom: 20px;
            }
        }
        .licensing-term {
            margin-top: 30px;
            legend {
                padding-bottom: 10px;
            }
            .field {
                line-height: 30px;
            }
        }
        .license-select {
            margin-top: 60px;
            h3 {
                margin-top: 20px;
                margin-bottom: 0;
                padding: 0;
            }
            p.type-description {
                line-height: 25px;
            }
            fieldset {
                margin-top: 20px;
                p {
                    margin-top: 0;
                    line-height: 25px;
                }
                legend {
                    margin-bottom: 5px;
                }
                .field {
                    p {
                        line-height: 30px;
                        @media (max-width: 431px) {
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        #paywallInformation {
            margin-top: 60px;
        }
        .license-terms {
            margin-top: 60px;
        }
        input.preferenceSave {
            background: $black;
            padding: 15px 80px;
            border: 0;
            color: $white;
            font-weight: 700;
            text-transform: uppercase;
            &:disabled {
                background: #ccc;
            }
        }
    }
    h1 {
        font-family: $proxima;
        font-weight: 400;
        font-size: 2.7rem;
        @media (max-width: 600px) {
            font-size: 2.2rem;
        }
    }
    h2 {
        font-family: $proxima;
        text-transform: uppercase;
        font-size: 1rem;
        color: $black;
        font-weight: 700;
        letter-spacing: 1px;
        padding-bottom: 4px;
        border-bottom: 4px solid $black;
    }
    h3 {
        font-family: $proxima;
        font-weight: 700;
        font-size: 1rem;
        margin-top: 50px;
    }
    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
        legend {
            margin: 0;
            padding: 0;
            font-weight: 700;
        }
    }
    .outofsite {
        display: none;
    }
    label {
        color: $formGrey;
        font-size: 15px;
        z-index: 9;
        position: absolute;
        top: 14px;
        left: 10px;
        cursor: text;
        transition: all 0.1s;
        &.outofsite {
            display: block;
        }
        &.shownformlabel {
            color: #000;
            font-size: 10px;
            font-weight: 700;
            top: 5px;
            left: 10px;
        }
        &.checkbox-label,
        &.radio-label {
            position: initial;
            color: #000;
        }
    }
    .field .clicked label {
        top: 5px;
        left: 10px;
        font-size: 10px;
        font-weight: 700;
        color: $black;
    }
    .columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        @media (max-width: 600px) {
            display: block;
        }
        .col {
            fieldset {
                width: 100%;
                max-width: 450px;
                // width: 450px;
                margin-top: 20px;
                margin-bottom: 30px;
                @media (max-width: 600px) {
                    width: 100%;
                }
                p {
                    margin-top: 5px;
                }
            }
        }
    }
    .field {
        p {
            margin: 0;
            padding: 0;
            position: relative;
        }
    }
    #id_additional_comments {
        border: 1px solid $formGrey;
        color: $formGrey;
        font-family: $proxima;
        font-size: 0.8rem;
        width: 100%;
    }
    #licensing-form-add-item-btn {
        font-style: italic;
        margin-top: 20px;
        margin-bottom: 40px;
        display: block;
    }
    .terms-of-use-container {
        height: 375px;
        background: #f8f7f7;
        overflow-y: scroll;
        -webkit-overflow-scrolling: always;
        position: relative;
        border: 2px solid #a3a3a3;
        padding: 10px 30px;
        margin-bottom: 30px;
        p,
        li {
            font-family: $proxima;
            line-height: 1.7rem;
            font-size: 1.1rem;
        }
    }

    #estimatedPrice {
        padding: 20px 20px 13px 20px;
        background-color: black;
        color: white;
        text-align: center;
        font-weight: bold;
        letter-spacing: 1px;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        z-index: 10;
        #dollarValue {
            margin: -5px 0 0 10px;
            font-size: 25px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
