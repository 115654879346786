footer {
    width: 100%;
    margin: 0 auto;
    padding: 35px 20px 120px 20px;
    background: $black;
    box-sizing: border-box;

    .footer-container {
        max-width: 1400px;
        margin: 0 auto;
        display: grid;
        gap: 50px;
        grid-template-columns: 300px 1fr;
        font-family: $proxima;
        color: $white;
        font-weight: 700;
        font-size: 0.95rem;

        @media (max-width: 1000px) {
            text-align: center;
            display: block;
        }

        .footerNav {
            color: $white;
            font-family: $proxima;
            font-size: 0.95rem;
            font-weight: 700;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;

            @media (max-width: 1000px) {
                margin-top: 50px;
            }

            @media (max-width: 800px) {
                grid-template-columns: repeat(12, 1fr);
                gap: 72px 0;
            }

            @media (max-width: 600px) {
                display: block;
            }

            .footerNavItem {
                @media (max-width: 800px) {
                    grid-column: span 4;

                    &:nth-of-type(4) {
                        grid-column: 3 / 7;
                        justify-self: center;
                    }

                    &:nth-of-type(5) {
                        grid-column: 7 / 11;
                        justify-self: center;
                    }
                }

                @media (max-width: 600px) {
                    margin-bottom: 55px;
                }
            }

            p {
                margin: 0 0 2px 0;
                font-size: 18px;

                &.spacing {
                    margin-top: 20px;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    a {
                        color: $white;
                        font-family: $proxima;
                        font-weight: 400;
                    }
                }
            }
        }

        .footerLogo {
            padding: 20px 0 0 25px;

            @media (max-width: 1000px) {
                padding-left: 0;
            }

            p {
                font-size: 18px;
            }
        }
    }

    .footerLinks {
        text-align: center;
        color: $white;
        font-family: $proxima;
        margin-top: 40px;
        font-size: 0.85rem;

        p {
            display: inline-block;

            span {
                padding: 0 20px;

                @media (max-width: 600px) {
                    display: block;
                }

                a {
                    color: $white;
                    font-family: $proxima;
                    font-size: 14px;
                    line-height: 2.5;

                    &#ot-sdk-btn {
                        color: $white !important;
                        font-family: $proxima !important;
                        font-size: 0.85rem !important;
                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}

.followicons {
    span {
        width: 32px;
        background: $white;
        color: $black;
        height: 32px;
        border-radius: 50%;
        border: 2px solid $black;
        display: inline-flex;
        align-items: center;
        text-align: center;
        font-size: 22px;
        &:hover {
            background: $black;
            color: $white;
            border: 2px solid $white;
        }
        a {
            text-align: center;
            width: 32px;
            height: 32px;
            &:hover {
                color: $white;
            }
        }
    }
}
