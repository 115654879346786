.video-category-duration {
    font-family: $proxima;
    font-size: 16px;

    @media (max-width: 600px) {
        color: $digital-grey;
        font-size: 14px;
    }

    .video-duration {
        font-weight: bold;

        @media (max-width: 600px) {
            font-weight: normal;
        }
    }
}

.video-header {
    padding: 40px 50px;
    background-color: $black;

    @media (max-width: 800px) {
        padding: 75px 32px;

        h1 {
            font-size: 32px;
        }
    }

    @media (max-width: 600px) {
        padding: 40px 24px;

        h1 {
            font-size: 24px;
        }
    }

    .video-header-text {
        max-width: 1150px;
        margin: auto;

        h1 {
            color: $white;
            font-family: $proxima;
            font-size: 48px;
        }

        p {
            color: $white;
            font-family: $minion;
            font-size: 24px;
            line-height: 1.4;

            @media (max-width: 800px) {
                font-size: 22px;
            }

            @media (max-width: 600px) {
                margin-bottom: 0;
                font-size: 20px;
            }
        }
    }
}

.videos-container {
    max-width: 1150px;
    margin: 70px auto;
    padding: 0 32px;
    font-family: $minion-condensed;

    @media (max-width: 1000px) {
        margin-top: 40px;
    }

    .video {
        position: relative;

        h2 {
            font-family: $proxima;
            font-size: 32px;
        }

        .video-title {
            margin: 5px 0 15px;
            font-family: $minion-condensed;
            font-size: 44px;
            line-height: 1.3;

            @media (max-width: 600px) {
                font-size: 26px;
            }

            p {
                font-family: $minion-condensed;
            }
        }

        .video-summary {
            font-family: $minion-condensed;
            font-size: 24px;
            font-weight: 400;

            @media (max-width: 600px) {
                display: none;
            }
        }
    }

    .video-category-duration {
        margin-top: 20px;
    }
}

.video-category-container {
    max-width: 1150px;
    margin: 0 auto 100px;
    padding: 0 32px;

    .video {
        padding: 40px 0;
        border-top: 2px solid #eee;

        @media (max-width: 600px) {
            padding: 10px 0;
        }

        h2 {
            font-family: $proxima;
            font-size: 32px;
        }
    }

    .videoContainer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 25px;

        @media (max-width: 1000px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }

        .videoThumb {
            .videoImage img {
                width: 100%;
                height: auto;
            }

            .videoTitle {
                p {
                    margin: 10px 0 5px;
                    font-family: $minion-condensed;
                    font-size: 25px;
                    line-height: 1.2;

                    @media (max-width: 600px) {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    a.white-btn,
    button.white {
        width: auto;
        margin: 40px auto;
        margin-left: 50%;
        transform: translateX(-50%);
        padding: 0 32px;
        border: 3px solid $black;
        font-size: 18px;
        display: inline-block;

        @media (max-width: 600px) {
            margin-left: auto;
            transform: translateX(0);
            display: block;
        }
    }
}

// Category page
.video-category {
    max-width: 1400px;
    margin: 0 auto;
    margin-bottom: 100px;
    padding: 0 50px;
    font-family: $minion-condensed;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 300px;
    gap: 50px;

    @media (max-width: 1000px) {
        padding: 0 32px;
        display: block;
    }

    @media (max-width: 600px) {
        padding: 0 24px;
    }

    h1 {
        margin: 30px 0;
        color: $black;
        font-family: $proxima;
        font-size: 32px;
    }

    .videoThumb {
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: 314px minmax(0, 1fr);
        gap: 40px;

        @media (max-width: 800px) {
            display: block;
        }

        .videoImage img {
            width: 100%;
        }

        .videoTitle {
            p {
                margin: 0;
                color: $black;
                font-size: 30px;
                line-height: 130%;

                @media (max-width: 600px) {
                    font-size: 26px;
                }
            }

            p.video-summary {
                margin: 10px 0;
                font-size: 22px;
                font-weight: 400;

                @media (max-width: 800px) {
                    display: none;
                }
            }

            .video-category-duration {
                color: $digital-grey;
                font-size: 16px;
                display: none;

                @media (max-width: 600px) {
                    font-size: 14px;
                }

                .video-duration {
                    font-weight: normal;
                }
            }
        }
    }
}

// Video detail page
.video-detail-container {
    max-width: 1400px;
    margin: 40px auto;
    padding: 0 35px;
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 48px;

    @media (max-width: 1200px) {
        display: block;
    }

    .category {
        margin: 30px 0;
        font-family: $proxima;
        font-size: 22px;
        font-weight: bold;
        line-height: 1;

        @media (max-width: 600px) {
            font-size: 16px;
        }
    }

    h1.headline {
        @media (max-width: 1000px) {
            font-size: 41px;
        }

        @media (max-width: 600px) {
            font-size: 26px;
        }
    }

    .description {
        font-size: 32px;
        line-height: 1.3;

        @media (max-width: 1000px) {
            font-size: 24px;
        }

        @media (max-width: 600px) {
            font-size: 20px;
        }
    }

    .video-category-duration {
        font-size: 18px;

        .video-duration {
            font-weight: normal;
        }
    }

    .white-btn {
        width: auto;
        margin: 50px 0;
        padding: 11px 35px;
        color: $black;
        background: $white;
        border: 4px solid $black;

        @media (max-width: 600px) {
            width: 100%;
            max-width: none;
            display: block;
        }
    }

    .nav-tags {
        strong {
            margin-right: 10px;
        }

        a {
            margin: 0 10px 15px 0;
            padding: 10px 15px;
            background-color: #eee;
            display: inline-block;

            @media (max-width: 600px) {
                font-size: 14px;
            }
        }
    }

    .related-videos {
        .videoThumb {
            margin-bottom: 40px;

            .videoImage img {
                width: 100%;
            }

            .videoTitle {
                p {
                    margin: 5px 0;
                    font-size: 26px;
                }

                .video-category-duration {
                    color: $digital-grey;
                    font-size: 14px;
                    font-weight: normal;
                }
            }
        }
    }
}
