.issue-page {
    .main-content {
        width: 1160px;
        max-width: 100%;
        @media (max-width: 1000px) {
            width: auto;
        }
        @media (max-width: 600px) {
            overflow-x: hidden;
        }
    }
    .issue-cover-wrapper-background {
        width: 100%;
        margin: 0;
        padding: 0;
        background: $black;
    }
    .issue-cover-wrapper {
        width: 1160px;
        margin: 0 auto;
        padding: 80px 0;
        color: $white;
        @media (max-width: 1000px) {
            width: 100%;
        }
        @media (max-width: 600px) {
            padding: 40px 0;
        }
        .issue-cover-container {
            display: grid;
            grid-template-columns: 355px 1fr;
            align-items: center;
            gap: 80px;
            @media (max-width: 1000px) {
                grid-template-columns: 240px 1fr;
                gap: 40px;
                padding: 0 32px;
            }
            @media (max-width: 600px) {
                display: block;
                padding: 0 32px;
            }
        }
        &:after {
            top: unset;
            left: -5px;
            right: unset;
            bottom: -5px;
        }

        .current-issue {
            margin: 40px auto 0;
            text-align: center;
            font-family: $proxima;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 1;
            position: relative;
            z-index: 2;
        }

        .issue-cover {
            img {
                width: 100%;
                max-width: 350px;
                height: auto;
                position: relative;
                z-index: 2;
                @media (max-width: 1230px) {
                    padding: 0 0 0 24px;
                }
                @media (max-width: 834px) {
                    padding: 60px 0 0 0;
                }
                @media (max-width: 600px) {
                    max-width: unset;
                }
            }
            .issue-cover-title {
                margin-top: 10px;
                h3 {
                    text-align: center;
                    margin: 0;
                    font-family: $proxima;
                    font-size: 18px;
                    line-height: 160%;
                }
            }
        }
        .issue-detail-copy-section {
            h1 {
                font-family: $proxima;
                font-size: 48px;
                font-weight: 700;
                margin: 0;
                padding: 0;
                @media (max-width: 1000px) {
                    font-size: 32px;
                    line-height: 1.3;
                }
                @media (max-width: 600px) {
                    margin-top: 30px;
                    font-size: 32px;
                    line-height: 1;
                }
            }
            p {
                font-size: 24px;
                line-height: 1.4;
                margin-top: 10px;
                padding-top: 0;
                @media (max-width: 834px) {
                    font-size: 20px;
                }
            }
            .issue-detail-button-group {
                display: flex;
                @media (max-width: 834px) {
                    display: block;
                }
                p {
                    width: 30%;
                    margin-right: 3%;
                    @media (max-width: 834px) {
                        width: 100%;
                        margin-right: initial;
                    }
                }
                a {
                    text-align: center;
                    color: $black;
                    text-decoration: none;
                    padding: 8px 10%;
                    font-family: $proxima;
                    font-weight: bold;
                    font-size: 18px;
                    display: block;
                }
                .issue-detail-button {
                    background: $white;
                }
                .issue-detail-sub-button {
                    background: $sunburst-yellow;
                }
            }
        }
    }

    .issue-articles {
        margin-top: 64px;
        display: grid;
        grid-template-columns: 5fr 2fr;
        gap: 48px;

        @media (max-width: 1000px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 600px) {
            display: block;
        }

        .article-list {
            border-bottom: none;
            margin: 0;
            padding: 0;
            @media (max-width: 600px) {
                padding-bottom: 0;
            }

            h2 {
                height: 0;
                font-family: $proxima;
                font-size: 28px;
            }

            .article-wrapper {
                padding: 32px 0;
                display: flex;

                @media (max-width: 834px) {
                    display: flex;
                    a {
                        width: 100%;
                    }
                }

                @media (max-width: 600px) {
                    display: grid;
                    grid-template-columns: 1fr;
                    padding: 20px 0;
                }

                &:first-of-type {
                    border: none;
                    padding: 24px 0 0;
                }

                .feature-thumbnail {
                    width: 240px;
                    height: auto;
                    vertical-align: middle;
                    @media (max-width: 834px) {
                        width: 100%;
                        max-width: 365px;
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-bottom: 16px;
                        max-width: unset;
                    }
                }

                .text-wrapper {
                    max-width: 492px;
                    margin-left: 28px;
                    display: inline-block;
                    vertical-align: top;

                    @media (max-width: 834px) {
                        max-width: none;
                        margin-left: 32px;
                        width: 100%;
                    }

                    @media (max-width: 600px) {
                        margin-left: unset;
                    }

                    h3 {
                        margin: 0 0 5px;
                        font-size: 30px;
                        font-weight: normal;
                        line-height: 130%;
                        @media (max-width: 600px) {
                            font-size: 26px;
                        }
                    }

                    p {
                        margin: 5px 0;
                        font-size: 22px;
                        font-family: $minion;
                        line-height: 130%;
                    }

                    .byline {
                        margin-top: 10px;

                        span {
                            margin: 0;
                            padding: 12px 0;
                            color: $digital-grey;
                            font-family: $proxima;
                            font-size: 16px;
                            line-height: 140%;

                            @media (max-width: 600px) {
                                padding: 0;
                            }

                            &.author {
                                margin-left: 3px;
                            }

                            a {
                                color: $digital-grey;
                            }
                        }
                    }
                }
            }

            .article-wrapper.is-sidebar {
                margin-left: 60px;
                padding-top: 0;
                border: none;
                position: relative;

                &:before {
                    content: '\002794';
                    color: #000;
                    font-size: 3rem;
                    position: absolute;
                    top: -25px;
                    left: -65px;
                }
            }
        }

        .department-list {
            width: 100%;
            max-width: 353px;
            @media (max-width: 834px) {
                max-width: initial;
                border-top: 1px solid $divider;
            }

            h2 {
                margin-bottom: 0;
                text-decoration: none;
            }

            .article-wrapper {
                padding: 0;
                border: none;

                @media (max-width: 800px) {
                    display: block;
                }

                .text-wrapper {
                    margin: 0;
                    padding: 15px 0;
                    .department {
                        font-family: $proxima;
                        font-size: 0.9rem;
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }

                    h3 {
                        margin: 0;
                        font-size: 26px;
                        font-weight: normal;
                        line-height: 140%;
                    }
                }
            }

            .article-wrapper.is-sidebar {
                margin-left: 50px;
                padding-top: 5px;
                border: none;
                position: relative;

                &:before {
                    top: 16px;
                    left: -52px;
                }

                .text-wrapper:before {
                    display: none;
                }
            }
        }
    }
}

// Archive Styles
.main-content-archive {
    margin-bottom: 60px !important;
    padding-top: 30px !important;
    @media (max-width: 600px) {
        padding-top: 30px !important;
    }
    h2 {
        font-family: $minion;
        font-size: 40px;
        font-weight: 700;
        margin-top: 40px;
        @media (max-width: 600px) {
            padding-top: 30px !important;
        }
    }
}
.issue-archive {
    h1 {
        margin-top: 55px;
        font-size: 2.8rem;
        font-weight: normal;
        line-height: 65px;
    }

    h2 {
        margin: 0;
        font-size: 2.3rem;
        font-weight: normal;
    }

    .archive-list {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 32px;
        @media (max-width: 1000px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        .issue {
            margin-bottom: 10px;

            img {
                width: 100%;
                height: auto;
            }

            .issue-month {
                margin: 5px 0;
                font-family: $proxima;
                text-align: center;
                font-size: 16px;
            }
        }
    }
}
.issue-archive-year {
    h1 {
        font-family: $proxima;
    }
    h2 {
        font-family: $proxima;
    }
    .filter {
        font-family: $proxima;
    }
    .archive-list {
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;

        @media (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 834px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 390px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .issue {
            img {
                width: 100%;
                height: auto;
            }
            .issue-month {
                margin: 0;
                font-family: $proxima;
                text-align: center;
            }
        }
    }
}
