.placer-right {
    min-width: 300px;
    min-height: 250px;
    margin: 0 auto;
    text-align: center;
}

.advertisement.fullwidth.adtop {
    top: 0;
    left: 0;
    right: 0;
}

/* Sticky sidebar ads on article pages */
body {
    --sidebar-slot-height: 1500px;
    --sidebar-slot-gap: 20px;
}
.articleRight {
    // Use flex layout to grow ad wrapper last-child
    display: flex;
    flex-direction: column;

    & > * {
        flex: 0 0 auto;
    }

    .ad-sidebar-new-wrapper {
        height: var(--sidebar-slot-height);

        &:last-child {
            flex: 1 0 auto;
            height: auto;
            margin-bottom: 200px; // match .tag-list
            min-height: var(--sidebar-slot-height);
        }

        .ad-sidebar-new {
            position: sticky;
            margin-top: var(--sidebar-slot-gap);
            top: calc(
                var(--header-main-height) + var(--header-cmstoolbar-height) +
                    var(--header-leaderboard-height) + var(--sidebar-slot-gap)
            );
        }
    }
}

body.leaderboard-transition,
body.leaderboard-lock {
    .articleRight {
        .ad-sidebar-new-wrapper {
            .ad-sidebar-new {
                transition: top 0.3s ease;
                top: calc(
                    var(--header-main-height) + var(--header-cmstoolbar-height) +
                        var(--sidebar-slot-gap)
                );
            }
        }
    }
}

/* global advertisement elements */
.advertisement {
    &.fullwidth {
        text-align: center;
        margin: 0 auto;
        padding: 15px 0;
        border-top: 1px solid $divider;
        border-bottom: 1px solid $divider;
        width: 100%;
        text-align: center;
        display: grid;
        align-content: center;
    }
    &.sticky {
        position: sticky;
        top: 130px;
    }
}

.ad-slot-no-title:before {
    display: none;
}

// ADS
// width > 1048
.ad-wrapper {
    height: 380px;
}

.ad-sidebar-wrapper {
    display: none;

    @media (min-width: 1000px) {
        position: relative;
        display: block;
        width: 300px;
        z-index: 4;

        .ad-slot {
            position: sticky;
            z-index: 5;
            width: 300px;
            min-height: 250px;
            margin-bottom: 24px;
        }
    }
}

.ad-slot {
    display: grid;
    margin: 0 auto;
    place-content: center;
    &.ad-slot-header {
        display: block !important;
    }
}

.ad-wrapper.calc-full-width {
    height: 380px;
    clear: both;
}
.ad-container {
    display: flex;
    text-align: center;
    width: 100%;
    max-width: 1076px;

    .ad-slot {
        width: 100%;
        border-top: 1px solid $divider;
        border-bottom: 1px solid $divider;
        margin: 24px auto;
        height: 380px;
        display: grid;
        place-items: center;
    }
}

.article-columns.single-column .ad-container {
    // center ad for single column articles
    right: -800px;
}

.ad-article-slot {
    max-height: 360px;
    align-self: center;
}

#leaderboardAd {
    padding: 0;
    background: $black;
}

#smithsonianmag_atf_leaderboard_new {
    margin: 0 auto;
    background: #000000;
}
