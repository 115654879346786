/* +++++ MSF-CSCZ +++++ is referring to the Magazine Submission Form, specifically
the Country, State, City & Zip/postal fields that need to be in a table to match the layout in the approved Figma designs for the redesign. */

.msf-cscz {
    form {
        width: 100%;
        max-width: 917px;
        font-family: $proxima;

        @media (max-width: 600px) {
            width: auto;
        }

        label {
            margin-bottom: 8px;
            font-size: 18px;
            font-weight: bold;
            display: block;

            .required {
                color: $red;
            }
        }
        fieldset {
            margin: 0 0 20px;
            padding: 0;
            border: none;
            outline: none;
            position: relative;

            input,
            select,
            textarea {
                width: 100%;
                padding: 8px 12px;
                font-size: 0.9rem;
                box-sizing: border-box;
                display: block;
                border: 2px solid $black;
            }
            input[type='checkbox'] {
                width: auto;
                display: inline-block;
            }
            select {
                width: 100%;
                max-width: 100%;
                color: #9e9e9e;
                option:not(:first-of-type) {
                    color: $black;
                }
            }
            textarea {
                height: 180px;
            }
        }
        button,
        .button {
            width: 130px;
            margin: 40px 0;
            padding: 8px 20px;
            background-color: $black;
            color: $white;
            font-size: 0.9rem;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1px;
            display: block;
            cursor: pointer;
        }
    }
}

.msf-cscz-table {
    width: 100%;
    .msf-cscz-table-cell {
        width: 49.5%;
        display: inline-block;
        @media (max-width: 600px) {
            width: 100%;
        }
    }
}
