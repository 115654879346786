.article-template.content-list-page {
    .category-section-menu {
        max-width: 100%;
        box-sizing: border-box;

        @media (max-width: 600px) {
            padding: 32px 24px 0;
        }
        nav {
            border-bottom: 1px solid #eee;
            @media (max-width: 600px) {
                border-bottom: none;
            }

            ul {
                padding: 35px 0 25px;

                @media (max-width: 600px) {
                    padding: 20px 0 22px;
                    border-bottom: none;
                }
            }
        }
    }

    #main-content {
        padding: 40px 48px;

        @media (max-width: 1000px) {
            padding: 32px;
        }

        @media (max-width: 600px) {
            // padding: 32px 24px;
            padding: 0;
        }
    }

    .main-content {
        width: $desktop_large;
        max-width: 100%;
        margin: 80px auto 200px;
        padding: 40px 0 0;
        box-sizing: border-box;

        @media (max-width: 1000px) {
            width: auto;
            margin: 80px auto 0;
        }

        @media (max-width: 600px) {
            padding-top: 10px;
        }

        h2 {
            margin: 0;
            font-family: $proxima;
            font-size: 28px;

            @media (max-width: 600px) {
                margin-bottom: 10px;
            }
        }

        .content-featured-list {
            @media (max-width: 1000px) {
                grid-template-columns: repeat(2, 1fr);

                .content-featured-item.first {
                    grid-column: 1 / span 2;
                    grid-row: 1 / span 2;
                }
            }

            @media (max-width: 600px) {
                display: block;
            }
            h3 {
                font-size: 25px;
            }
            .first {
                h3 {
                    font-size: 44px;
                }
            }
        }

        &.content-list {
            padding-top: 0;

            .content-list-columns {
                @media (max-width: 1000px) {
                    display: block;
                }

                .author-box.by-line.single-author.footer-author-box.author-list-page {
                    margin-top: 20px;
                }

                .content-list-item {
                    h3 {
                        margin-top: -7px;
                        font-size: 30px;

                        @media (max-width: 600px) {
                            font-size: 20px;
                        }
                    }

                    p.dek {
                        @media (max-width: 1000px) {
                            display: none;
                        }
                    }
                }
            }
        }

        .home-newsletter .leade h3 {
            margin-bottom: 32px;
        }
    }
}
