body.podcast {
    .podcast-leaderboard {
        background: #1c1c1c;
        padding: 100px 0;
        position: relative;
        top: -3px;
        @media (max-width: 1000px) {
            padding: 60px 0 100px;
        }
        @media (max-width: 600px) {
            padding: 50px 0;
        }
        .leaderboard-container {
            width: 1060px;
            margin: 0 auto;
            color: $white;
            display: grid;
            grid-template-columns: 266px 1fr;
            gap: 55px;
            align-items: center;
            @media (max-width: 1000px) {
                width: 90%;
                gap: 40px;
            }
            @media (max-width: 600px) {
                display: block;
                width: 100%;
            }
            h1 {
                margin: 0;
                font-size: 48px;
                font-weight: 600;
                font-family: $proxima;
                line-height: 1.2;
                @media (max-width: 1000px) {
                    font-size: 30px;
                    padding-top: 0;
                    padding-bottom: 0;
                }
                @media (max-width: 600px) {
                    font-size: 24px;
                    margin-top: 30px;
                    padding: 0 10px;
                }
            }
            p {
                margin-top: 30px;
                font-size: 24px;
                font-family: $minion;
                line-height: 1.4;
                @media (max-width: 1000px) {
                    font-size: 20px;
                    line-height: 1.3;
                    margin-top: 15px;
                }
                @media (max-width: 600px) {
                    margin-top: 20px;
                    padding: 0 10px;
                    font-size: 20px;
                }
            }
            figure {
                margin: 0;
                padding: 0;
                @media (max-width: 600px) {
                    padding: 0 10px;
                }
                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }
            .leaderboard-image {
                @media (max-width: 600px) {
                    padding: 0 20px;
                    width: 60%;
                    text-align: center;
                    margin: 0 auto;
                }
            }
            .leaderboard-text {
                position: relative;
                bottom: -25px;
                @media (max-width: 600px) {
                    padding: 0 20px;
                    bottom: 0;
                }
                figure {
                    text-align: right;
                    position: relative;
                    img {
                        width: 120px;
                        height: auto;
                    }
                }
            }
        }
    }
    .stream-links-box {
        background: $button;
        padding: 40px 0;
        text-align: center;
        .stream-links-container {
            width: 1060px;
            margin: 0 auto;
            @media (max-width: 1000px) {
                width: 100%;
                text-align: center;
            }
            @media (max-width: 600px) {
                width: 100%;
                text-align: center;
            }
            p {
                font-size: 18px;
                line-height: 1.4;
                margin: 0;
                padding: 0;
                font-family: $proxima;
                strong {
                    font-size: 18px;
                    color: $black;
                    font-weight: 700;
                }
            }
            figure {
                margin: 0;
                margin-top: 20px;
                padding: 0;
                padding-right: 20px;
                display: inline-block;
                @media (max-width: 600px) {
                    padding-right: 0;
                    text-align: center;
                    display: block;
                }
                img {
                    width: auto;
                    height: 64px;
                    @media (max-width: 600px) {
                        text-align: center;
                        display: inline;
                    }
                }
            }
        }
    }
    .related-series-box {
        background: $button;
        padding: 40px 0;
        text-align: center;
        margin: 80px 0;
        @media (max-width: 1000px) {
            margin-top: 40px;
        }
        @media (max-width: 600px) {
            margin-top: 40px;
        }
        .related-series-container-all {
            @media (max-width: 1000px) {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 20px;
                padding: 0 20px;
            }
            @media (max-width: 600px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                padding: 0 20px;
            }
        }
        .related-series-container {
            width: 1060px;
            margin: 0 auto;
            @media (max-width: 1000px) {
                width: 100%;
                text-align: center;
            }
            @media (max-width: 600px) {
                width: 100%;
                text-align: center;
            }
            h2 {
                margin-top: 0;
                font-family: $proxima;
                font-size: 28px;
                font-weight: 300;
                @media (max-width: 1000px) {
                    font-size: 24px;
                    padding: 0 20px;
                }
                @media (max-width: 600px) {
                    font-size: 22px;
                    padding: 0 20px;
                }
            }
            p {
                font-size: 1.125rem;
                margin: 0;
                padding: 0;
                line-height: 1.8;
                font-family: $proxima;
                strong {
                    font-size: 18px;
                    color: $black;
                    font-weight: 700;
                }
            }
            figure {
                margin: 0;
                margin-top: 20px;
                padding: 0;
                padding-right: 20px;
                display: inline-block;
                @media (max-width: 1000px) {
                    padding-right: 0;
                    display: block;
                }
                @media (max-width: 600px) {
                    padding-right: 0;
                    display: block;
                }
                img {
                    width: 221px;
                    height: auto;
                    @media (max-width: 1000px) {
                        width: 100%;
                    }
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
                p {
                    font-family: $proxima;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1.3;
                    padding-top: 10px;
                }
            }
        }
    }
    .podcast-content {
        width: 1160px;
        margin: 0 auto;
        @media (max-width: 1000px) {
            width: 100%;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
        h2 {
            font-size: 2.375rem;
            font-weight: 400;
            @media (max-width: 1000px) {
                padding: 0 20px;
            }
            @media (max-width: 600px) {
                padding: 0 20px;
            }
        }
        .paginationSection {
            padding-bottom: 200px;
            @media (max-width: 1000px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding: 0 25px 60px;
            }
            @media (max-width: 600px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding: 0 25px 60px;
            }
            .columns {
                &.right {
                    ul {
                        @media (max-width: 600px) {
                            text-align: right;
                        }
                    }
                }
            }
        }
        .podcast-episode-container {
            padding: 80px 0;
            border-top: 2px solid #ebebeb;
            &.first {
                border-top: 0;
                @media (max-width: 1000px) {
                    padding: 80px 20px 40px;
                }
                @media (max-width: 600px) {
                    padding: 80px 20px 40px;
                }
            }
            &.page-1-ep-1 {
                @media (max-width: 1000px) {
                    border-top: 0;
                    padding-top: 0;
                }
                @media (max-width: 600px) {
                    border-top: 0;
                    padding-top: 0;
                }
            }
            @media (max-width: 1000px) {
                padding: 80px 20px;
            }
            @media (max-width: 600px) {
                padding: 80px 20px;
            }
        }
        .podcast-episode {
            .podcast-episode-item {
                display: grid;
                grid-template-columns: 266px 1fr;
                gap: 30px;
                @media (max-width: 1000px) {
                    display: block;
                }
                @media (max-width: 600px) {
                    display: block;
                }
                p {
                    font-size: 22px;
                    font-family: $minion;
                    line-height: 1.3;
                    margin-top: 0;
                    &.timestamp {
                        margin: 0;
                        font-size: 16px;
                        font-family: $proxima;
                        color: #555;
                        @media (max-width: 600px) {
                            margin-top: 40px;
                        }
                    }
                    &.transcript {
                        font-weight: 700;
                        font-size: 18px;
                        font-family: $proxima;
                        line-height: 1.3;
                        @media (max-width: 600px) {
                            padding-top: 0;
                        }
                        span {
                            padding-left: 10px;
                        }
                    }
                }
                audio {
                    width: 80%;
                    @media (max-width: 1000px) {
                        width: 100%;
                    }
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
                h3 {
                    margin: 9px 0;
                    font-size: 44px;
                    font-family: $minion-condensed;
                    line-height: 1.3;
                    font-weight: 300;
                    @media (max-width: 1000px) {
                        font-size: 30px;
                        line-height: 1.3;
                    }
                    @media (max-width: 600px) {
                        font-size: 40px;
                        line-height: 1.1;
                    }
                }
                .image {
                    @media (max-width: 1000px) {
                        text-align: center;
                        margin: 0 auto;
                        margin-bottom: 40px;
                    }
                    @media (max-width: 600px) {
                        text-align: center;
                        margin: 0 auto;
                    }
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 5%;
                        object-fit: cover;
                        filter: drop-shadow(-1px 5px 3px #bfbfbf);
                        @media (max-width: 1000px) {
                            width: 230px;
                            height: auto;
                        }
                        @media (max-width: 600px) {
                            width: 60%;
                            height: auto;
                        }
                    }
                }
            }
        }
        .podcast-related-articles {
            h4 {
                font-size: 28px;
                font-weight: 600;
                font-family: $proxima;
                line-height: 1.3;
                margin-bottom: 0;
                padding-bottom: 10px;
                @media (max-width: 600px) {
                    font-size: 24px;
                    line-height: 1.2;
                }
            }
            .related-articles-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 48px;
                @media (max-width: 600px) {
                    display: block;
                }
                img {
                    width: 100%;
                    height: auto;
                    @media (max-width: 600px) {
                        display: none;
                    }
                }
                p {
                    margin-top: 0;
                    &.related-headline {
                        font-size: 30px;
                        font-family: $minion-condensed;
                        line-height: 1.3;
                        margin-bottom: 0;
                        @media (max-width: 1000px) {
                            font-size: 22px;
                            line-height: 1.3;
                        }
                        @media (max-width: 600px) {
                            font-size: 20px;
                            line-height: 1.4;
                        }
                    }
                }
                .section {
                    font-family: $proxima;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1.3;
                    padding: 3px 0;
                    margin-bottom: 0;
                    @media (max-width: 600px) {
                        font-size: 16px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
