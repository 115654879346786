.home-tower-newsletter {
    h2 {
        font-family: $proxima;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.3;
    }
    .nl-option {
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: 24px;
        font-family: $proxima;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4;
        color: $digital-grey;
        align-items: start;
        label {
            font-family: $proxima;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.3;
            color: $black;
        }
        p {
            margin-top: 0;
        }
        input[type='checkbox'] {
            border: 2px solid $black;
            height: 20px;
            width: 20px;
        }
    }
    .nl-option-info {
        padding: 10px 0;
        input[type='email'] {
            border: 3px solid $black;
            width: 277px;
            padding: 15px 0 15px 17px;
        }
        input[type='submit'] {
            margin-top: 0;
        }
    }
    p {
        font-family: $proxima;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        margin-top: 0;
    }
}

.sfmc {
    .emaillookup {
        .field {
            padding: 30px 0 20px 0;
            p {
                font-size: 18px !important;
                font-family: $proxima;
                background: $button;
                padding: 4px 10px;
            }
        }
    }
    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
        .field-group-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            margin-bottom: 10px;
            label {
                font-family: $proxima;
                font-size: 18px;
                font-weight: 700;
            }
            &.name {
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }
    }
    .partner-sites {
        border-top: 1px solid $divider;
        padding-top: 30px;
        margin-top: 20px;
    }
    .contact-information {
        margin-top: 30px;
        h2 {
            background: $button;
            font-size: 18px !important;
            font-weight: 700 !important;
            padding: 15px;
            font-family: $proxima;
        }
    }
    .fieldWrapper {
        .preferenceGrid {
            display: grid;
            grid-template-columns: 25px 1fr;
            gap: 20px;
            i {
                padding-top: 5px;
                text-align: center;
            }
        }
        label {
            font-family: $proxima;
            font-size: 18px;
            font-weight: 700;
            em {
                font-weight: normal;
            }
        }
        p {
            font-family: $proxima !important;
            font-size: 18px !important;
            color: $digital-grey !important;
            &.optedin {
                color: $black !important;
            }
        }
    }
    input[type='text'] {
        width: 100%;
        padding: 7px 7px;
        font-size: 18px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    input[type='email'] {
        width: 100%;
        padding: 7px 7px;
        font-size: 18px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    input[type='checkbox'] {
        display: inline-block;
        width: 25px;
        height: 25px;
    }
    .required {
        color: $red;
    }
    .buttonbox {
        .required-field {
            font-size: 14px;
            margin-bottom: 0;
            margin-top: 20px;
            font-family: $proxima !important;
        }
        .centeritem {
            &.submit {
                margin-bottom: 10px;
            }
        }
        .sfmc-info {
            font-size: 16px !important;
            margin-top: 0;
            font-family: $proxima !important;
            .preferenceSave {
                margin-bottom: 10px;
            }
        }
    }
}
