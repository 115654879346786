.member-services {
    width: 100%;
    .main-content {
        .ms-subscription-buttons {
            display: grid;
            gap: 24px;
            grid-template-columns: repeat(3, 1fr);
            padding-bottom: 64px;
            @media (max-width: 834px) {
                gap: 8px;
            }
            .ms-button-black {
                text-align: center;
                padding: 8px 0;
                background-color: $black;
                border: 2px solid $black;
                a {
                    text-decoration: none;
                    color: $white;
                    font-family: $proxima;
                    font-weight: 700;
                    font-size: 18px;
                }
            }
            .ms-button-white {
                text-align: center;
                padding: 8px 0;
                border: 2px solid $black;
                a {
                    text-decoration: none;
                    font-family: $proxima;
                    font-weight: 700;
                    font-size: 18px;
                }
            }
        }
        .member-benefits {
            width: 100%;
            max-width: 917px;
            .mb-list {
                .mb-list-section {
                    display: inline-flex;
                    margin-bottom: 36px;
                    @media (max-width: 834px) {
                        display: block;
                    }
                    .mb-list-section-image img {
                        @media (max-width: 834px) {
                            width: 100%;
                            padding: 0 0 24px;
                        }
                    }
                    .mb-list-section-copy {
                        padding-left: 48px;
                        align-content: center;
                        @media (max-width: 834px) {
                            width: 100%;
                            padding: 0;
                            align-content: initial;
                        }
                        .title {
                            font-size: 24px;
                            font-family: $proxima;
                            font-weight: 700;
                            line-height: 140%;
                        }
                        p {
                            padding-top: 8px;
                            font-size: 18px;
                            line-height: 160%;
                        }
                    }
                }
            }
        }
        .heading {
            font-family: $minion;
            font-size: 44px;
            line-height: 130%;
        }
    }
}
