// font imports
@import url('https://use.typekit.net/fvn3yjf.css');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

// Shut off dark mode
:root {
    color-scheme: only light !important;
    --background-color: $white;
    --text-color: $black;
    --link-color: $black;
}

html[data-theme='light'] {
    --background-color: $white;
    --text-color: $black;
    --link-color: $black;
}

html[data-theme='dark'] {
    --background-color: $white !important;
    --text-color: $black !important;
    --link-color: $black !important;
}

// font variables
$proxima: 'proxima-nova', sans-serif;
$minion-condensed: 'minion-pro-condensed', serif;
$minion: 'minion-pro', serif;
$playfair: 'Playfair Display', serif;
$opensans: 'Open Sans', sans-serif;

// colors
$black: #242424;
$white: #fcfcfc;
$sky-blue: #74d1ea;
$sunburst-yellow: #ffe27c;
$red: #c00;
$magenta: #d91f86;
$digital-grey: #777;
$divider: #d7d9db;
$button: #eee;
$menu-rule: #d9d9d9;

// museum day colors
$violet: #36255a;
$gray: #3d3c3b;
$dark-gray: #161616;
$darkest-gray: #333;
$heather: #4f397d;
$orange: #f2792f;
$lemon: #fed931;

// sizes
$desktop_large: 1400px;
$desktop_default: 1076px;
$toolbar-height: 46px; // has to be even number

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    hyphens: manual;
    background: $white;
}

a {
    color: $black;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

/* site header
.cms-toolbar-expanded header {
    top: $toolbar-height !important;
    .navigation {
        top: $toolbar-height !important;
    }
}
*/

/* Hide stuff */
.outofsight {
    display: none;
}

a.skip-main {
    left: -9999999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -9999999;
    &:focus {
        color: $white;
        background-color: $black;
        left: 0;
        top: 0;
        width: 100%;
        margin: 0;
        padding: 3px 0;
        text-align: center;
        font-size: 1.2em;
        z-index: 9999999;
        font-family: $proxima;
        font-weight: 700;
        position: relative;
        display: inline-table;
        outline: 2px $white;
    }
    &:active {
        color: $white;
        background-color: $black;
        left: 0;
        top: 0;
        width: 100%;
        margin: 0;
        padding: 3px 0;
        text-align: center;
        font-size: 1.2em;
        z-index: 9999999;
        border: 2px solid $white;
        font-family: $proxima;
        font-weight: 700;
        position: relative;
        display: inline-table;
    }
}

@import '_header.scss';
@import '_shared.scss';
@import '_legacy.scss';
@import '_widgets.scss';
@import '_ads.scss';
@import 'article/_category.scss';
@import 'article/_article.scss';
@import 'article/_magazine.scss';
@import 'article/_longform.scss';
@import 'article/_photoessay.scss';
@import 'article/_specialreport.scss';
@import 'article/_specialcontent.scss';
@import '_homepage.scss';
@import '_museumday.scss';
@import '_flatpage.scss';
@import '_voices.scss';
@import '_video.scss';
@import '_newsletters.scss';
@import '_social.scss';
@import '_podcast.scss';
@import '_licensing.scss';
@import '_aboutpage.scss';
@import '_footer.scss';
@import '_print.scss';
@import '_sub-feedback-forms.scss';
@import '_memberservices.scss';

#ot-sdk-btn-floating.ot-floating-button {
    bottom: 120px !important;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
    color: $black !important;
    border: 0 !important;
    padding: 0 !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.main-content {
    max-width: $desktop_large;
    margin: 0 auto;
    padding: 0 20px;
    @media (max-width: 1100px) {
        max-width: $desktop_default;
    }
}

.embedly-plugin {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe,
    embed,
    object {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// Hide empty images
img[src=''] {
    display: none;
}
